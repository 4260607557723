<!-- 发票列表 -->
<template>
  <div class="invoice_List_page">
    <myTable title="发票列表" :handle="false" :columns="columns" :list="list">
      <template slot="top_block">
        <div class="code_block">
          <el-button @click="addStu" class="table_top_btn_default">添加发票信息</el-button>
        </div>
      </template>
    </myTable>
    <comdialog :list="fromList" ref="comdialog" :params.sync="form" :edit="alertStatus" title="添加发票信息" @onSubmit="onSubmit" @close="comdialogClose">
      <template slot="desc">
        <div class="template_desc">
          系统默认向所填电子邮箱发送电子发票，如需纸质发票请联系客服
        </div>
      </template>
    </comdialog>
    <comPagination :params="params" @changePage="changePage"></comPagination>
  </div>
</template>

<script>
  import myTable from '@/components/admin/table.vue'
  import comdialog from '@/components/admin/editDialog.vue'

  import comPagination from '@/components/admin/pagination.vue'
  export default {
    name: 'invoiceList',
    components: {
      myTable,
      comdialog,
      comPagination,
    },
    data() {
      var checkName = (rule, value, callback) => {
        if (this.common.validateLength(value,50)) {
          callback();
        } else {
          callback(new Error("请输入正确单位名称"));
        }
      };
      var checkBankName = (rule, value, callback) => {
        if(value){
          if (this.common.validateLength(value,50)) {
            callback();
          } else {
            callback(new Error("请输入正确单位开户行名称"));
          }
        }else{
          callback();
        }
      };
      var checkInvoiceMoney = (rule, value, callback) => {
        if (this.common.integerSevenDecimalTwoNoContainsZero(value)) {
          callback();
        } else {
          callback(new Error("请输入正确开票金额"));
        }
      };
      var checkChinese = (rule, value, callback) => {
        let flag = this.common.validateChinese(value);
        if (flag) {
          callback(new Error("请输入正确单位税号"));
        } else {
          callback();
        }
      };
      var checkPhone = (rule, value, callback) => {
        if(value){
          if (this.common.validateLength(value,10)) {
            callback();
          } else {
            callback(new Error("请输入正确电话"));
          }
        }else{
          callback();
        }
      };
      var checkAccount = (rule, value, callback) => {
        if(value){
          if (this.common.validateLength(value,25)) {
            callback();
          } else {
            callback(new Error("请输入正确账号"));
          }
        }else{
          callback();
        }
      };
      var checkRemittanceAccount = (rule, value, callback) => {
        if(value){
          if (this.common.validateLength(value,25)) {
            callback();
          } else {
            callback(new Error("请输入正确汇款账户"));
          }
        }else{
          callback();
        }
      };
      var checkEmail = (rule, value, callback) => {
        if(!this.common.validateLength(value,25)){
          callback(new Error("请输入正确邮箱"));
        }else{
          if (this.common.isEmail(value)) {
            callback();
          } else {
            callback(new Error("请输入正确邮箱"));
          }
        }

      };
      return {
        //班级列表
        columns: [
        {
          prop: 'id',
          label: '编号',
        },
        {
          prop: 'accountNumber',
          label: '金额/元',
        },
        {
          prop: 'time',
          label: '日期',
        },
        {
          prop: 'name',
          label: '名称',
        },
        {
          prop: 'status',
          label: '状态',
        }, ],
        list: [],
        // 弹窗状态  新增/编辑/查看
        alertStatus: '',
        form: {
          name: '',
          paragraph: '',
          address: '',
          phone: '',
          bank: '',
          account: '',
          accountNumber: '',
          remittance: '',
          time: '',
          emali: '',
        },
        //分页
        params: {
          page: 0,
          size: 10,
          total: 0,
        },
        fromList: [
        {
          prop: 'name',
          type: 'text',
          label: '单位名称',
          required: true,
          rules: [{ required: true, validator: checkName, trigger: "blur" }],
        },
        {
          prop: 'address',
          type: 'text',
          label: '单位地址',
          required: false,
        },
        {
          prop: 'bank',
          type: 'text',
          label: '单位开户行名称',
          required: false,
          rules: [{ required: false, validator: checkBankName, trigger: "blur" }],
        },
        {
          prop: 'accountNumber',
          type: 'text',
          label: '开票金额',
          required: true,
          rules: [{ required: true, validator: checkInvoiceMoney, trigger: "blur" }],
        },
        {
          prop: 'time',
          type: 'date',
          label: '汇款时间',
          required: false,
        },
        {
          prop: 'paragraph',
          type: 'text',
          label: '单位税号',
          required: true,
          rules: [{ required: true, validator: checkChinese, trigger: "blur" }],
        },
        {
          prop: 'phone',
          type: 'text',
          label: '电话',
          required: false,
          rules: [{ required: false, validator: checkPhone, trigger: "blur" }],
        },
        {
          prop: 'account',
          type: 'text',
          label: '账号',
          required: false,
          rules: [{ required: false, validator: checkAccount, trigger: "blur" }],
        },
        {
          prop: 'remittance',
          type: 'text',
          label: '汇款账户',
          required: false,
          rules: [{ required: false, validator: checkRemittanceAccount, trigger: "blur" }],
        },
        {
          prop: 'emali',
          type: 'text',
          label: '邮箱',
          required: true,
          rules: [{ required: true, validator: checkEmail, trigger: "blur" }],
        }],
      }
    },
    created() {},
    mounted() {
      this.init()
    },
    methods: {
      init() {
        this.getInfo()
      },
      onSubmit(val) {
        console.log(val)
        let data = { ...val }
        data.userID = this.$store.state.userInfo.userId,
          data.schoolId = this.$store.state.userInfo.schoolId,
          this.api.admin.addInvoice(data).then(res => {
            console.log(res)
            if (res.flag) {
               this.$notice({
                type: 'success',
                message: res.message
              }).isShow()
              
            } else {
                              this.$notice({
                  message: res.message
                }).isShow()
            }
          })
          this.$refs.comdialog.isClose()
          this.getInfo()
      },
      //关闭编辑弹窗
      comdialogClose() {
        this.form = {
          name: '',
          paragraph: '',
          address: '',
          schoolId: '',
          phonk: '',
          acne: '',
          bacount: '',
          accountNumber: '',
          remittance: '',
          time: '',
          emali: '',
        }
      },
      //打开编辑弹窗
      addStu() {
        this.$refs.comdialog.isOpen()
        this.alertStatus = 'add'
      },
      //分页 todo 待测试
      changePage(val) {
        console.log(val)
        this.params.page = val
        this.getInfo()
      },
      getInfo() {
        let data = {
          schoolId: this.$store.state.userInfo.schoolId,
        }
        this.api.admin.findInvoiceList(this.params, data).then((res) => {
          console.log(res)
          res.data.list.forEach((item)=>{
            item.status = item.status == '0' ? '未开票' : '已开票';
            // return item;
          });
          this.list = res.data.list
          this.params.total = res.data.total
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .invoice_List_page {
    .template_desc {
      color: #cb1b31;
      font-size: 20px;
      font-weight: bold;
      padding-left: 200px;
      margin: 30px 0;
    }
  }
</style>